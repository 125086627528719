<template>
  <div class="content-right">
    <div class="right-word">
      <h3>Consumption Record</h3>
    </div>
    <div class="list" ref="listRef">
      <div class="list-header">
        <h3>Consumption time</h3>
        <h3>Consumption amount</h3>
      </div>
      <div class="list-Box">
        <div v-for="item in list" :key="item.id" class="list-box">
          <div>{{ item.createtime }}</div>
          <div>{{ item.changeCoin }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, watch } from "vue";
import { recordsAPI } from "@/api/center.js";
const query = reactive({
  type: "2",
  pageSize: 10,
  pageNum: 1,
});
const listRef = ref(null);
const lazyFlag = ref(false);
const list = ref([]);

onMounted(() => {
  getList(query);

  listRef.value.addEventListener("scroll", function () {
    msgScroll();
  });
});
const getList = async (obj) => {
  let { rows, code, total } = await recordsAPI(obj);
  if (code == 200) {
    if (query.pageNum > total / 10) {
      list.value = list.value.concat(rows);
      lazyFlag.value = true;
      console.log("已经到底了");
      return;
    } else {
      list.value = list.value.concat(rows);
    }
  }
  console.log(rows, "rows");
};

function msgScroll() {
  if (
    listRef.value.scrollTop + listRef.value.clientHeight > listRef.value.scrollHeight ||
    listRef.value.scrollTop + listRef.value.clientHeight == listRef.value.scrollHeight
  ) {
    if (lazyFlag.value == false) {
      query.pageNum += 1;
      getList(query);
    } else {
      console.log("到底dddddd");
    }
  }
}
</script>

<style lang="scss" scoped>
.content-right {
  width: 550px;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.3);

  .list {
    margin: 0 22px;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 10px;
    height: 300px;
    overflow: auto;

    .list-header {
      display: flex;
      // justify-content: space-around;
      margin-bottom: 10px;

      h3 {
        width: 50%;
        text-align: center;
      }
    }

    .list-box {
      display: flex;
      justify-content: space-around;
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;
      line-height: 30px;

      div {
        width: 50%;
        text-align: center;
      }
    }
  }

  .right-word {
    padding: 5px 22px;

    h3 {
      line-height: 50px;
      color: #ffff;
    }

    .content-name {
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      color: #ffff;
      border-bottom: 1px solid #5a5e5f;
      font-size: 12px;

      .content-user {
        margin-right: 60px;
      }

      .right {
        color: #c4e93d;
        margin-left: 200px;
        cursor: pointer;
      }
    }
  }
}
</style>
